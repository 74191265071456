<template>
    <div class="orderDetail" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never">
            <el-page-header @back="goBack" />
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-form @submit.native.prevent :inline="true" size="small" label-position="right" label-width="80px">
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="类型">
                            <el-select v-model="form.type" disabled>
                                <el-option label="采购订单" :value="0" />
                                <el-option label="单方销售" :value="1" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="创建人">
                            <el-input v-model="form.creator" style="width: 215px" readonly />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="创建日期">
                            <el-input v-model="form.createTime" style="width: 215px" readonly />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="销售机构">
                            <el-input v-model="form.repoName" readonly style="width: 215px" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="进货机构">
                            <el-input v-model="form.deptName" readonly style="width: 215px" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="客户">
                            <el-input v-model="form.customer" readonly style="width: 215px" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="客户电话">
                            <el-input v-model="form.customerMobile" readonly style="width: 215px" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="客户地址">
                            <el-input v-model="form.customerAddress" readonly style="width: 215px" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <ef-remark v-model="form.remark" readonly />
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px" v-if="form.reviewStatus == 0 ? false : true">
            <el-button type="primary" @click="handleAudit" size="small">审核详情</el-button>
            <PickupReturnAudit ref="PickupReturnAudit" :review-privilege-flag="'menu.sale.order.review'" />
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-table
                border
                stripe
                style="width: 100%"
                :data="form.detailParams"
                size="mini"
                show-summary
                :highlight-current-row="true"
                max-height="500"
            >
                <el-table-column label="序号" type="index" width="60" align="center" fixed="left" />
                <el-table-column label="商品名称" width="180" fixed="left">
                    <template slot-scope="scope">
                        {{ skuByGoodsCode(scope.row.goodsCode).name }}
                    </template>
                </el-table-column>
                <el-table-column label="条码" width="120">
                    <template slot-scope="scope">
                        <div v-for="b in skuByGoodsCode(scope.row.goodsCode).bars" :key="b">{{ b }}</div>
                    </template>
                </el-table-column>
                <el-table-column label="规格" width="100">
                    <template slot-scope="scope">
                        {{ skuByGoodsCode(scope.row.goodsCode).specs }}
                    </template>
                </el-table-column>
                <el-table-column label="类目" width="100">
                    <template slot-scope="scope">
                        {{ skuByGoodsCode(scope.row.goodsCode).category }}
                    </template>
                </el-table-column>
                <el-table-column label="单位" width="80">
                    <template slot-scope="scope">
                        {{ skuByGoodsCode(scope.row.goodsCode).unit }}
                    </template>
                </el-table-column>
                <el-table-column label="品牌" width="100">
                    <template slot-scope="scope">
                        {{ skuByGoodsCode(scope.row.goodsCode).brand }}
                    </template>
                </el-table-column>
                <el-table-column label="批发价" width="80" prop="showPrices" />
                <template v-if="hasBigUnitPrivilege">
                    <el-table-column label="包装因子" width="100" prop="packFactors" />
                    <el-table-column label="采购箱数" min-width="100" prop="bigUnitCounts" />
                    <el-table-column label="散装数量" min-width="100" prop="bulkCounts" />
                </template>
                <el-table-column label="批发数量" width="100" prop="counts" />
                <el-table-column label="批发金额" width="120" prop="saleShowMoney" />
                <el-table-column label="赠品数量" width="100" prop="giftCounts" />
                <el-table-column label="总计数量" width="100" prop="totalCounts" />
            </el-table>
        </el-card>
    </div>
</template>
<script>
import Util from 'js/Util';
import UrlUtils from 'js/UrlUtils';
import MoneyUtils from 'js/MoneyUtils';
import EfRemark from 'components/EfRemark';
import PickupReturnAudit from 'components/PickupReturnAudit';
import StockBizCommon, { PropertyConverter } from 'js/StockBizCommon';
import GoodsCommon from 'js/GoodsCommon';

export default {
    name: 'OrderDetail',
    components: { EfRemark, PickupReturnAudit },
    props: {
        code: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            baseUrl: '/trade/wholesale/order',
            form: {
                type: '',
                createTime: '',
                creator: '',
                deptName: '',
                repoName: '',
                deptCode: '',
                repoCode: '',
                reviewStatus: '',
                remark: '',
                customer: '',
                customerMobile: '',
                customerAddress: '',
                detailParams: [],
            },
            //所选商品详情列表
            goodsDetailMap: new Map(),
            url: {
                review: '/biz/review/list?bizCode=',
            },
            codes: [],
            tableData: [],
            reviewData: [],

            hasBigUnitPrivilege: false,
        };
    },
    mounted() {
        //查询数据
        UrlUtils.QueryRemote(this, `${this.baseUrl}/info_c/${this.code}`, (data) => {
            Util.copyProperties(data, this.form);
            //是否启用大单位权限
            UrlUtils.HasBigUnitPrivilege(this, this.form.repoCode, async (rst) => {
                this.hasBigUnitPrivilege = rst;
                const arr = await StockBizCommon.makeStockBizDetailGoodsAndParams(
                    data,
                    this.detailParamPropertyArrForShow(this.hasBigUnitPrivilege)
                );
                this.goodsDetailMap = arr.goodsDetails;
                this.form.detailParams = arr.detailParams;
            });
        });
        UrlUtils.QueryRemote(this, this.url.review + this.code, (data) => {
            this.reviewData = data;
        });
    },
    methods: {
        detailParamPropertyArrForShow(hasBigUnitPrivilege) {
            const rst = [
                'codes',
                'goodsCode',
                'counts',
                'giftCounts',
                new PropertyConverter('prices', 'showPrices', (prices) => {
                    return MoneyUtils.moneyToYuan(prices);
                }),
            ];
            if (hasBigUnitPrivilege) {
                rst.push(
                    new PropertyConverter('packFactors', 'packFactors', (packFactors, detailParam) => {
                        return Util.isEmpty(packFactors) ? 1 : packFactors;
                    }),
                    new PropertyConverter('bigUnitCounts', 'bigUnitCounts', (bigUnitCounts, detailParam) => {
                        return Util.isEmpty(bigUnitCounts)
                            ? Math.floor(detailParam.counts / detailParam.packFactors)
                            : bigUnitCounts;
                    }),
                    new PropertyConverter('bulkCounts', 'bulkCounts', (bulkCounts, detailParam) => {
                        return Util.isEmpty(bulkCounts) ? detailParam.counts % detailParam.packFactors : bulkCounts;
                    })
                );
            }
            if (this.purchaseSaleOrderTypeFlag()) {
                //采购订单类型，采购价格和采购数量为0，表示配送中心单方赠送的商品
                rst.push(
                    new PropertyConverter('applyPrices', 'showApplyPrices', (applyPrices, detailParam) => {
                        return Util.isEmpty(applyPrices) ? 0 : MoneyUtils.moneyToYuan(applyPrices);
                    }),
                    new PropertyConverter('applyCounts', 'applyCounts', (applyCounts, detailParam) => {
                        return Util.isEmpty(applyCounts) ? 0 : applyCounts;
                    })
                );
            }
            rst.push(
                new PropertyConverter(null, 'saleShowMoney', (saleShowMoney, detailParam) => {
                    return this.computeShowMoney(detailParam);
                }),
                new PropertyConverter(null, 'totalCounts', (totalCounts, detailParam) => {
                    return this.decimal(this.computeCount(detailParam)).add(detailParam.giftCounts).toNumber();
                })
            );
            return rst;
        },
        computeCount(detailParam) {
            let counts;
            if (this.hasBigUnitPrivilege) {
                //启用大单位
                counts = this.decimal(detailParam.packFactors || 1)
                    .mul(detailParam.bigUnitCounts || 0)
                    .add(detailParam.bulkCounts || 0)
                    .toNumber();
            } else {
                //未启用大单位
                counts = detailParam.counts || 0;
            }
            return counts;
        },
        computeShowMoney(detailParam) {
            return this.decimal(detailParam.counts || this.computeCount(detailParam))
                .mul(detailParam.showPrices || 0)
                .toNumber();
        },
        goodsByCode(goodsCode) {
            return this.goodsDetailMap.get(goodsCode);
        },
        skuByGoodsCode(goodsCode) {
            return this.goodsByCode(goodsCode).sku;
        },
        handleAudit() {
            this.$refs.PickupReturnAudit.show(this.code, this.baseUrl);
        },
        purchaseSaleOrderTypeFlag() {
            return this.form.type === 0;
        },
    },
    filters: {
        skuTypeDesc(type) {
            return GoodsCommon.skuTypeDesc(type);
        },
        m(m) {
            return MoneyUtils.formatMoney(m);
        },
    },
};
</script>

<style scoped>
.el-input {
    width: auto;
}

.el-form-item {
    width: auto;
}
</style>
